import { SET_ADDCOUPON } from '../actions';

const couponadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDCOUPON:
      return [...action.value];  
    default: return state;
  }
}

export default couponadd;
