import { SET_EXPORTUSER } from '../actions';

const exportuser = (state = [], action) => {
  switch (action.type) {
	case SET_EXPORTUSER:
      return [...action.value];  
    default: return state;
  }
}

export default exportuser;
