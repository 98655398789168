import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { cmscontentURL } from '../Config/Config';
import CKEditor from 'ckeditor4-react';

import {GET_EDITCMS,GET_CMSDETAIL  }  from '../../actions'; 

class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
		const cmsId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			name:'',
			cms_id:'',
			logo_content:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
            image: [],
			cms_image_name: '',
			cms_image_preview: '',
			isHidden: false,
			cmsId: cmsId,
			Loading:false,
			fileName: [],
			thumbDefault: '',
			thumbtypeimg: [],	
			admin_id: localStorage.getItem('admin_id')
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onChangeHandler = this.onChangeHandler.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.removeImage = this.removeImage.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
	   this.props.getCmsDetail(cmsId);
    }
	

	
	
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
     onEditorChange( evt ) {
    	console.log(evt.editor.getData())
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            logo_content: evt.editor.getData()
        } );
		}, 1000);
     
    }
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('CMS Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

   handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value });
      if(selectedVehicle.value === ''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }
      let vehicletruck = selectedVehicle.label;
      let lowercasetext = vehicletruck.toLowerCase();
      if(lowercasetext == 'truck'){
      	this.setState({enableweight: true})
      }else{
      	this.setState({enableweight: false, truck_weight:0})
      }
   }

  onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cms_image_name: file.name,
        cms_image_preview: reader.result,
        image: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }
  

onThumbChanged = (e) => {	
    this.setState({
      thumbDefault: e.currentTarget.value
      });
	  
  }
 

 handleFormSubmit = () => {

			if(this.validateForm()){

				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					id : formPayload.cms_id,
					name: formPayload.name,
					logo_content:formPayload.logo_content,
					cms_image   : formPayload.image,
					status      :status,
					
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       

			    this.props.getEditCms(formData,config);
			}
	}

	validateForm() {
		const {name,logo_content,cms_image_preview} = this.state;
		let errors = 0;
	
		if (!name) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

    componentWillReceiveProps(Props){


    	   if(Props.cmsdetail !== this.props.cmsdetail){
    		if(Object.keys(Props.cmsdetail).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.cmsdetail[0].status === "success"){

						const formdata = Props.cmsdetail[0].cmslist;
						
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({name:formdata.name});

						var callfunc = this;
						setTimeout(function () {
						callfunc.setState({logo_content:formdata.landing_content});
						}, 1000);

						//this.setState({logo_content:formdata.landing_content});
						this.setState({cms_id: formdata.id});
						this.setState({cms_image_preview: formdata.landing_logo});
					  
					}
    		}
    	}
		
         if(Props.cmsedit !== this.props.cmsedit){

    		if(Object.keys(Props.cmsedit).length > 0){

    			this.setState({ Loading: false });

    			const formdata = Props.cmsedit[0].cmslist;
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}

				this.setState({name:formdata.name});
			    this.setState({cms_id: formdata.id});
		        //this.setState({cms_image_preview: formdata.landing_content});
		        var callfunc = this;
				setTimeout(function () {
				callfunc.setState({logo_content:formdata.landing_content});
				}, 1000);

                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.cmsedit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			this.props.history.push('/cms');
    		 }
    	}   
      }

  removeImage = () => {
  	 this.setState({cms_image_preview:''})	  
  }
	
  render() {

	let carImg = '';
	let preview = '';
	
	/*if (user_image_preview!== null && user_image_preview!== '') {
	    carImg = carImageUrl + "/" + user_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}*/

	
  	const {selectedOption,cms_image_preview} = this.state;

	if (cms_image_preview!== null && cms_image_preview!== '') {
	    carImg = cmscontentURL + "/" + cms_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}
	
	
	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="bus" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label> Name:</label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorname"></div>
				</div>

				<div className="form-group">					
  					<label>Logo Content:</label>
 				     <CKEditor
					data={this.state.logo_content}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/>
  					<div className="errorlogo_content"></div>
  				</div>
			</div>
			<div className="form-right">
				
				<div className="form-group">
			    <label>Logo Image:</label>
				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
                </div>
                {preview}
                {/*cms_image_preview?<a href="javascript:void(0);" onClick={this.removeImage}>X</a>:''*/}
                </div>

				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	cmsdetail     : state.cmsdetail,
  	cmsedit       : state.cmsedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	
    getCmsDetail: (cmsId) =>{
       dispatch({ type: GET_CMSDETAIL,cmsId });
    },
    getEditCms: (formPayload) =>{
       dispatch({ type: GET_EDITCMS, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));