import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';


	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			name:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			read_only:true,
			full_control: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		if(name === 'access_control'){
			this.setState({full_control: !this.state.full_control});
			this.setState({read_only: !this.state.read_only});
		 }

  }


  handleSubmit = () => {
	if(this.validateForm()){
		const formdata = this.state;
		var qs = require('qs');
		var status = '';

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
		}else{
		status = formdata.selectedvalue;
		}
		if(formdata.read_only){
			var control = 'readonly';
		}else{
			var control = 'grant';
		}
		var postObject ={
			admin_id: localStorage.getItem('admin_id'),
			name : formdata.name,
			subadmin_privilege: control,
			status: status
		}
	
		axios.post(apiUrl+"Rolesmanagement/add",qs.stringify(postObject)).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html(this.props.history.push('/rolemanagement'));
					}
					.bind(this),
					3000
					);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
	}

	validateForm() {
		const {name} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		
		  if(!name){
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcat_name').html('');
		}
		
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	

  render() {

	const {selectedOption} = this.state;


    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="rolemanagement" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Role</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label> Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name}/>
					<div className="errorcat_name"></div>
				</div>
				<div className="form-group">
					<label>Grant SubAdmin Privileges
					: </label><br />
					<input onChange={this.handleInputChange} type="radio" name="access_control" checked={this.state.read_only}/>
					<span>Read Only (List)</span>
						<input onChange={this.handleInputChange} type="radio"  name="access_control" checked={this.state.full_control}/>
					<span>Grant Full Control (Add, Edit, Import, Export And Delete)</span>
 				</div> 	
				
			</div>
                  
			<div className="form-right">

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));