import { SET_USERDETAILLIST } from '../actions';

const userdetails = (state = [], action) => {
  switch (action.type) {
	case SET_USERDETAILLIST:
      return [...action.value];  
    default: return state;
  }
}

export default userdetails;
