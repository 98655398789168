import { SET_TEMPLATEDETAIL } from '../actions';

const templatedetail = (state = [], action) => {
  switch (action.type) {
	case SET_TEMPLATEDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export default templatedetail;
