import { SET_UPDATEPROFILE } from '../actions';

const updateprofile = (state = [], action) => {
  switch (action.type) {
	case SET_UPDATEPROFILE:
      return [...action.value];  
    default: return state;
  }
}

export default updateprofile;
