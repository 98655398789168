import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Logout extends Component {

      constructor(props) {
        super(props); 
        this.state = {
        
          };
            this.props.history.push('/');
    }

    getunsettoken(){
          /*console.log(localStorage.getItem("admin_id"))
          if(localStorage.getItem("admin_id") == 'undefined' && localStorage. getItem('admin_id') == '' && localStorage. getItem('admin_id') == null){
          console.log('123')
          this.props.history.push('/');
          }*/
    }
    render() {
      return (
        <h4 className="loading-text">
          Logging out...
        </h4>
      );
    }
  }
  const mapStateTopProps = (state) => {
    return {
    }
  }

  const mapDispatchToProps = (dispatch) => {
    }
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Logout));